import React from "react"

const QuoteTextAndCtaBelow = ({ ctaBlockContent, ctaBlockTextOnButton, ctaBlockTitle, ctaLinkOut, logoSupportingQuote, openCtaLinkInNewTab, quoteName, quoteNamePosition, textToUseForQuote }) => {

  return (
    <section className="content azure-background">
   <div className="content-block">
   <div className="product-feefo-logo-quote-holder">
   {logoSupportingQuote ? (
      <div className="product-feefo-logo-holder">
      <img src={logoSupportingQuote} alt="Feefo Logo"></img>
    </div>
    ) : null}

      <div className="product-quote-holder">
      <div className="product-quote-text-holder" dangerouslySetInnerHTML={{ __html: textToUseForQuote }}/>

                     <div className="product-quote-author-holder">
               <strong>{quoteName} , </strong>{quoteNamePosition}            </div>
               </div>
   </div>
      </div>
</section>

  )
  }
export default QuoteTextAndCtaBelow
